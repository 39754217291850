.card {
  display: grid;
  margin: 30px;
  max-width: max-content;
}
form {
  max-width: 350px;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 3px 24px -8px rgba(0, 0, 0, 0.75);
}

.name-field {
  margin-bottom: 40px;
}

button {
  margin-top: 20px;
  padding: 10px;
  background: transparent;
  border-radius: 5px;
}

.render-data {
  min-width: 1100px;
  min-height: 500px;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 3px 24px -8px rgba(0, 0, 0, 0.75);
}

.render-card {
  min-width: 600px;
  min-height: 500px;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 3px 24px -8px rgba(0, 0, 0, 0.75);
}

h3 {
  color: #2f72da;
}

span {
  color: black;
}
