.card {
  display: grid;
  max-width: max-content;
}

h3 {
  color: #2f72da;
}

span {
  color: black;
}

.version {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: small;
  font-weight: 400;
  color: lightgray;
}
